export class User {
    
    constructor(

       public id: number,
       public user_id: number,
       public utype: string,
       public email: string,
       public ukey: string,
       public name: string,
       public bio?: string,
       public occupation?: string,
       public website?: string,
       public location?: string


    ){

    }

}