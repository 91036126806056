import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { config } from 'src/config';
import { AuthResponse } from './appInterface/auth-responce-interface';
import { UserResponse } from './appInterface/user-responce-interface';
import { Subject , BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from './appModels/user-model';
import { Router } from '@angular/router';
import { Cart } from './appModels/user-cart-model';

@Injectable({
  providedIn: 'root'
})
export class AuthService 
{
  private user: BehaviorSubject<User>;
  private cart: BehaviorSubject<Cart>;
  constructor(private http:HttpClient, private router:Router)
   { 
    this.user = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('UserData')));
    this.cart = new BehaviorSubject<Cart>(JSON.parse(localStorage.getItem('CartData')));
    //console.log(this.getcurrentUserValue());
   }
   signUp(name,email,utype)
   {
      return this.http.post<AuthResponse>(`${config.BASE_URL}${config.ENDPOINT_SIGNUP}`,{
        name:name,
        email:email,
        utype:utype
      });
   }
   signIn(email,upass)
   {
    //const login_url = `${config.BASE_URL}web/merchant/unsigned/login/?email=${email}&upass=${upass}`;
    return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/unsigned/login/`,
      {
        params: new HttpParams()
        .set('email',email)
        .set('upass',upass)
      }
      ).pipe(
      tap(res=>{


        if(res.status==true)
        {
          if(res.content['user']['utype']=="S")
          {
            this.authUser(+res.content['profile']['id'],+res.content['profile']['user_id'],res.content['user']['utype'],res.content['user']['email']
            ,res.content['user']['ukey'],res.content['profile']['sname']);
          }
          else
          {
            this.authUser(+res.content['profile']['id'],+res.content['profile']['user_id'],res.content['user']['utype'],res.content['user']['email']
            ,res.content['user']['ukey'],res.content['profile']['cname'],res.content['profile']['bio'],res.content['profile']['occupation']
            ,res.content['profile']['website'],res.content['profile']['location'],);
          }
          
        }
        else
        {
          return;
        }
      })
      
    );
   }
  authUser(id,user_id,utype,email,ukey,name,bio?,occupation?,website?,location?)
   {
     let p_user;
     if(utype=="S")
     {
      p_user = new User(id,user_id,utype,email,ukey,name);
     }
     else
     {
      p_user = new User(id,user_id,utype,email,ukey,name,bio,occupation,website,location);
     }
      
      console.log('user=>',p_user);
      this.user.next(p_user);
      localStorage.setItem("UserData",JSON.stringify(p_user));
   }
   authLogin()
   {
     const userData=JSON.parse(localStorage.getItem('UserData'));
     let loggedinUser;
      //console.log(userData);
     if(!userData)
     {
        return;
     }
     if(userData.utype=="S")
     {
      loggedinUser= new User(userData.id,userData.user_id,userData.utype,userData.email,userData.ukey,userData.name);
     }
     else
     {
      loggedinUser= new User(userData.id,userData.user_id,userData.utype,userData.email,userData.ukey,userData.name,userData.bio,userData.occupation,
        userData.website,userData.location);
     }
    
     this.user.next(loggedinUser);
   }
   signOut()
   {
    this.user.next(null);
    localStorage.clear();
    //localStorage.removeItem('UserData');
    this.router.navigate(['/u/auth/login']);
    
   }
   public get currentUserValue(): User 
   {
    return this.user.value;
    } 
    forgetPasswd(email)
    {
      return this.http.get<AuthResponse>(`${config.BASE_URL}web/merchant/unsigned/forget-password`,{
        params: new HttpParams()
        .set('email',email)
      });
    }
    //SELLER
  AddProduct(pname,price,image_url,detail,ukey,seller_id)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.post<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/product/add`,{
        seller_id:seller_id,
        pname:pname,
        price:price,
        image_url:image_url,
        detail:detail
      },{headers:headers
      });
   }

   ViewSProduct(ukey,seller_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/product/list`, 
      {
        params: new HttpParams()
        .set('seller_id',seller_id),
        headers:headers
      });
   }

  ViewSsProduct(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/product/view`, 
      {
        params: new HttpParams()
        .set('id',p_id),
        headers:headers
      });
   }
   EditsProduct(pname,price,image_url,detail,ukey,id)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.put<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/product/edit`,{
        id:id,
        pname:pname,
        price:price,
        image_url:image_url,
        detail:detail
      },{headers:headers
      });
   }

   DeleteSsProduct(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.delete<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/product/delete`, 
      {
        params: new HttpParams()
        .set('id',p_id),
        headers:headers
      });
      
   }


   ViewSsProfile(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/profile/view`, 
      {
        params: new HttpParams()
        .set('id',p_id),
        headers:headers
      });
   }

   UpdateSProfile(sname,aname,mobile,country,pincode,
    state,city,address_line1,address_line2,address_landmark,instruction,ukey,id)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });
      return this.http.put<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/profile/edit`,{
        id:id,
        sname:sname,
        aname:aname,
        mobile:mobile,
        country:country,
        pincode:pincode,
        state:state,
        city:city,
        address_line1:address_line1,
        address_line2:address_line2,
        address_landmark:address_landmark,
        instruction:instruction
      },{headers:headers
      });
   }
   
   UpdateSPasswd(upass,ukey,id)
   {
     console.log(id);
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });
      return this.http.put<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/profile/change-pass`,{
        id:id,
        upass:upass
      },{headers:headers
      });
   }

   ViewSOrderList(ukey,customer_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/order/list`, 
      {
        params: new HttpParams()
        .set('seller_id',customer_id),
        headers:headers
      });
   }
   ViewSsOrder(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/order/view`, 
      {
        params: new HttpParams()
        .set('id',p_id),
        headers:headers
      });
   }
   ViewHomeData(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });
      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/home`, 
      {
        params: new HttpParams()
        .set('seller_id',p_id),
        headers:headers
      });
   }

   GetCourierList(ukey)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });
      return this.http.get<UserResponse>(`${config.BASE_URL}web/courier/signed/company/list`, 
      {
        headers:headers
      });
   }

   AssignCourier(ukey,user_id,order_id,type)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.post<UserResponse>(`${config.BASE_URL}web/courier/signed/company/assignment/create`,{
        user_id:user_id,
        order_id:order_id,
        type:type
      },{headers:headers
      });
   }
  
   updateTrackingId(ukey,tracking_id,order_id,status)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.put<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/order/update-tracking-id`,{
        tracking_id:tracking_id,
        order_id:order_id,
        status:status
      },{headers:headers
      });
   }
   getCourierDetails(ukey,tracking_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });
      return this.http.get<UserResponse>(`${config.BASE_URL}web/courier/signed/company/assignment/view`, 
      {
        params: new HttpParams()
        .set('tracking_id',tracking_id),
        headers:headers
      });
   }



   //customer
   ViewCProduct(ukey)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });
      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/product/explore`, 
      {
        headers:headers
      });
   }

   ViewCsProduct(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/product/detail`, 
      {
        params: new HttpParams()
        .set('id',p_id),
        headers:headers
      });
   }

   AddAddress(aname,mobile,country,pincode,
    state,city,address_line1,address_landmark,instruction,ukey,customer_id)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.post<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/address/add`,{
        customer_id:customer_id,
        aname:aname,
        mobile:mobile,
        country:country,
        pincode:pincode,
        state:state,
        city:city,
        address_line1:address_line1,
        address_landmark:address_landmark,
        instruction:instruction
      },{headers:headers
      });
   }
   ViewCAddress(ukey,customer_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/address/list`, 
      {
        params: new HttpParams()
        .set('customer_id',customer_id),
        headers:headers
      });
   }

   ViewCsAddress(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/address/view`, 
      {
        params: new HttpParams()
        .set('id',p_id),
        headers:headers
      });
   }
   EditCAddress(aname,mobile,country,pincode,
      state,city,address_line1,address_landmark,instruction,ukey,id)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.put<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/address/edit`,{
        id:id,
        aname:aname,
        mobile:mobile,
        country:country,
        pincode:pincode,
        state:state,
        city:city,
        address_line1:address_line1,
        address_landmark:address_landmark,
        instruction:instruction

      },{headers:headers
      });
   }
   DeleteCAddress(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.delete<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/address/delete`, 
      {
        params: new HttpParams()
        .set('id',p_id),
        headers:headers
      });
   }
   UpdateCProfile(cname,bio,occupation,website,location,ukey,id)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });
      return this.http.put<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/profile/edit`,{
        id:id,
        cname:cname,
        bio:bio,
        occupation:occupation,
        website:website,
        location:location
      },{headers:headers
      });
   }
   UpdateCPasswd(upass,ukey,id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });
      return this.http.put<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/profile/change-pass`,{
        id:id,
        upass:upass
      },{headers:headers
      });
   }
   SetCartItems(cartItem)
   {
    localStorage.setItem("cartItem",JSON.stringify(cartItem)); 
   }
   
   GetCartItems()
   {
     return JSON.parse(localStorage.getItem('cartItem'));
   }
   SetDefaultAddress(address_id)
   {
    localStorage.setItem("default_address",address_id); 
   }
   
   GetDefaultAddress()
   {
     return localStorage.getItem('default_address');
   }


   PlaceOrder(ukey,param)
   {
   
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });
      return this.http.post<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/order/add`,param,{headers:headers
      });
   }


   ViewCOrderList(ukey,customer_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/order/list`, 
      {
        params: new HttpParams()
        .set('customer_id',customer_id),
        headers:headers
      });
   }
   ViewCsOrder(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/order/view`, 
      {
        params: new HttpParams()
        .set('id',p_id),
        headers:headers
      });
   }

   ReturnInitiate(return_reason,order_id,ukey)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

     return this.http.put<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/order/return-initiate`,{
      id:order_id,
      return_reason:return_reason
    },{headers:headers
    });
   }

}
