import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  Form: FormGroup;
  loading = false;
  submitted = false;
  
  constructor(private fb:FormBuilder, 
    private _authservice:AuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.Form = new FormGroup({
      name: new FormControl(),
      email: new FormControl(),
      utype: new FormControl()
   });

   this.Form = this.fb.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    utype: ['', Validators.required]
});
}

get f() { return this.Form.controls; }

  onSubmit()
  {

    this.submitted = true;

    // stop here if form is invalid
    if (this.Form.invalid) {
        return;
    }

    this.loading = true;

    //console.log(this.Form.value)
    const name=this.Form.value.name;
    const email=this.Form.value.email;
    const utype=this.Form.value.utype;
    this._authservice.signUp(name,email,utype).subscribe(
      res=>
      {
        // console.log(res)
        // alert(res.message)
        this.loading = false;
        this.router.navigate(['/u/auth/login']);
      },
      err=>
      {
        console.log(err)
        this.loading = false;
      }
    );
  

  }
}
