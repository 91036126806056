import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './unsigned/auth/login/login.component';
import { UnsignedComponent } from './unsigned/unsigned.component';
import { RegisterComponent } from './unsigned/auth/register/register.component';
import { ForgetpassComponent } from './unsigned/auth/forgetpass/forgetpass.component';
import { SignedComponent } from './signed/signed.component';
import { P404Component } from './unsigned/p404/p404.component';
import { AuthComponent } from './unsigned/auth/auth.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
    { path:"", redirectTo:"u/auth/login", pathMatch:"full" },
    { path:"u", component:UnsignedComponent,
        children:[
            { path:"auth", component:AuthComponent,
                children:[
                    { path:"login", component:LoginComponent},
                    { path:"register", component:RegisterComponent},
                    { path:"forgetpass", component:ForgetpassComponent}
                ]
            }
        ]
    },
    { path:"s", component:SignedComponent,
        children:[
            { path:"s", loadChildren: () => import('./signed/seller/seller.module').then(m=>m.SellerModule) },
            { path:"c", loadChildren: () => import('./signed/customer/customer.module').then(m=>m.CustomerModule) }
        ],canActivate: [AuthGuard] 
    },
    { path:'**', component:P404Component},
  ];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true, preloadingStrategy:PreloadAllModules}) ],
    exports: [RouterModule]
  })

export class AppRoutingModule { } 